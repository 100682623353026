.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.main-content {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}
