.inputsWrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 8px;

  color: var(--secondary-grey);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.dataForm {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 16px;
  line-height: 24px;

  h4 {
    color: var(--secondary-grey);
    font-weight: 400;
  }

  p {
    color: var(--primary);
    font-weight: 700;
  }
}
