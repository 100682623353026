.button {
  height: 40px;
  width: 100%;
  border-radius: var(--border-radius-btn);
  background: var(--primary-color);
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  &:hover {
    background: var(--button-hover);
  }

  &:active {
    background: var(--button-active);
  }

  &:disabled {
    opacity: 0.3;
  }
}


