.Footer {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: #F8F8F8;
  color: #212121;
}

.container {
  width: 82%;
  margin: 0 auto;
  padding: 48px 0;

  display: flex;
  flex-direction: column;
  gap: 40px;
}

.line {
  border-bottom: 1px solid #D1D1D1;
}

.policy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;

  p {
    color: #6C6E79;
    line-height: 20px;

    a {
      color: #6C6E79;

      &:hover {
        color: #ff6b30;
      }
    }
  }
  img {
    height: 32px;
  }
}

.topFooter {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  img {
    height: 44px;
  }
}

.content {
  display: flex;
  gap: 32px;

  .col {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;

    h4 {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    a {
      color: #212121;
      font-weight: 500;

      &:hover {
        color: #FF6B30;
      }
    }

    div {
      font-weight: 500;
    }

    p {
      font-weight: 400;
    }
  }
}


@media (max-width: 1024px) {
  .topFooter {
    flex-direction: column;
  }

  .content {
    justify-content: space-between;
    flex-wrap: wrap;

    div {
      min-width: 190px;
    }
  }
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .container {
    width: 90%;
    gap: 32px;
  }

  .line {
    display: none;
  }

  .policy {
    flex-direction: column;
    align-items: start;

    img {
      order: 1;
    }

    p {
      order: 2;
    }
  }
}
