.NavBar {
  width: 100%;
  max-width: 1920px;
  height: 98px;
  margin: 0 auto;
  display: flex;
  background: #F8F8F8;
}

.container {
  margin: 0 auto;
  width: 82%;

  display: flex;
  align-items: center;
}

.navLinks {
 display: flex;
  width: 100%;
}

.list {
  display: flex;
  justify-content: center;
  gap: 24px;
  width: 100%;
}

.link {
  color: #212121;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  &:hover {
    color: #FF6B30;
  }
}

.navBurger {
  position: relative;
  z-index: 999;
  display: none;
  width: 32px;
  height: 32px;
  background: url("../../assets/image/IconBurger.svg");
}
.burgerClose {
  background: url("../../assets/image/IconBurgerClose.svg");
}

@media (max-width: 768px) {
  .NavBar {
    height: 80px;
    padding-top: 24px;
  }

  .container {
    width: 90%;
    justify-content: space-between;
  }

  .navLinks {
    display: none;
    background: #F8F8F8;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .list {
    margin: 20% auto;
    flex-direction: column;
    font-size: 16px;
    text-align: center;
    justify-content: flex-start;
  }

  .navLinksShow {
    display: flex;
  }

  .navBurger {
    display: block;
  }
}
